<template>
  <section class="home">
    <!--  <p class="mb-5">Lisbon is a captivating blend of historic charm, modern vibrancy, delicious cuisine, warm
            hospitality, and breathtaking views that will leave you feeling like you've stepped into a real-life fairytale.
        </p> -->
    <div v-if="isLoading">
      <vue-lottie :options="lottieOptions" :width="180" :height="450" />
    </div>

    <div v-else>



      <div class="categories-container pr-4">
        <b-button
          rounded
          class="mb-5 category--tab"
          :class="{ 'is-primary': activeTab === index }"
          v-for="(categoryKey, index) in categoriesKeys"
          :key="index"
          @click="onCategoryClick(index)"
        >
          <span>{{ categoryKey }}</span></b-button
        >
      </div>

      <TicketsAndActivitiesDesktop
        class="mb-6"
        v-if="isDesktopView"
        :is-experiences-ids="false"
        :Experiences="activities[categoriesKeys[activeTab]]"
        :Tickets="orderedTickets[categoriesKeys[activeTab]]"
      />
      <TicketsAndActivitiesContainer
        ref="activities"
        class="mb-6"
        v-else
        is-full-width
        :is-experiences-ids="false"
        :Experiences="activities[categoriesKeys[activeTab]]"
        :Tickets="orderedTickets[categoriesKeys[activeTab]]"
      />
    </div>

    <!-- <CustumTourContainer class="mt-6 mb-6" /> -->
    <!--   <div class="mt-6 mb-6 bg-light-blue">
      <Concierge :show-full-page="false" class="pt-3" />
      <div class="is-flex is-justify-content-center">
      <b-button
        rounded
        icon-right="arrow-right"
        class="mb-5 mt-5 button--md btn-blue"
        tag="router-link"
        to="/concierge"
        >Learn more
      </b-button>
    </div>
    </div>
 -->
    <!--  <p class="is-size-4 has-text-weight-bold mt-4 mb-5">Our insider tips</p>
    <BlogArticleCard class="blog-articles" :articles="articles" />
    <Testimonials class="mt-3 mb-3" />

    <p class="is-size-4 has-text-weight-bold mb-5 mt-6">
      Highlighted Attractions
    </p>
    <b-collapse
      animation="slide"
      v-for="(attraction, index) in Attractions"
      :key="index"
      open
      @open="isOpen = index"
    >
      <template #trigger="props">
        <div class="attraction">
          <img class="attraction--img" :src="attraction.img" />
          <p class="attraction--tile">
            <span class="highlight">{{ attraction.name }}</span>
          </p>
        </div>

        <div class="attraction-details--container">
          <p class="attraction--details">{{ attraction.description }}</p>
          <div
            class="collapse--btn is-flex is-align-items-center"
            v-if="attraction.experiences"
          >
            Ways to experience ({{
              attraction.experiences.length + attraction.tickets.length
            }})
            <a class="card-header-icon" v-show="props.open">
              <b-icon icon="chevron-up"></b-icon>
            </a>
            <a class="card-header-icon" v-show="!props.open">
              <b-icon icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </template>
      <TicketsAndActivitiesContainer
        :is-full-width="false"
        :Experiences="attraction.experiences"
        :Tickets="attraction.tickets"
      />
    </b-collapse> -->
  </section>
</template>

<script>
//import ExternalActivityCard from '@/components/elements/ExternalActivityCard'
//import Testimonials from "../components/Testimonials.vue";
//import BlogArticleCard from "../components/elements/BlogArticleCard.vue";
//import CustumTourContainer from "../components/CustumTourContainer.vue";
//import Concierge from "../Concierge.vue";
//import TourCard from "../components/elements/TourCard.vue";
import VueLottie from "vue-lottie";
import TicketsAndActivitiesContainer from "../components/TicketsAndActivitiesContainer.vue";
import { ActivityCategories as Categories } from "../enums/ActivityCategories";
import { mapState } from "vuex";
import { HelpersMixin } from "../mixins";
import { ActivitiesStates } from "../store/Storetypes";
import TicketsAndActivitiesDesktop from "@/components/TicketsAndActivitiesDesktop";
export default {
  props: ["orderedActivities", "isLoading", "tickets"],
  mixins: [HelpersMixin],
  components: {
    //TourCard,
    //Testimonials,
    //CustumTourContainer,
    //Concierge,
    TicketsAndActivitiesContainer,
    VueLottie,
    // ExternalActivityCard,
    // BlogArticleCard,
    TicketsAndActivitiesDesktop,
  },
  data() {
    return {
      isOpen: -1,
      activeTab: 0,
      categoryKeys: [],
      orderedTickets: {},
      Categories,
      lottieOptions: {
        animationData: require("@/assets/animations/loading-animation.json"),
        loop: true,
        autoplay: true,
      },
      articles: [
        {
          name: "Your Guide to Santos Populares: Portugal's Beloved June Celebrations.",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fimage%2062%20(2).png?alt=media&token=7c4e2d8f-f093-475c-a23a-39575d2ff62d",
          href: "your-guide-to-santos-populares-portugal's-beloved-june-celebrations",
          badge: {
            text: "Blog article",
            icon: require("../assets/newspaper-solid.svg"),
          },
          date: "June 13, 2024",
        },
        {
          name: "10 Best Things to Do in Lisbon in 2024: A Comprehensive Guide",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20263%20(2)%20(1).png?alt=media&token=fb4fc757-61b3-44e7-9290-74dd76a517fd",
          href: "10-best-things-to-do-in-lisbon-in-2024",
          badge: {
            text: "Blog article",
            icon: require("../assets/newspaper-solid.svg"),
          },
          date: "March 12, 2024",
        },
        {
          name: "Best things to see and do in Sintra in a day.",
          img: "https://images.pexels.com/photos/8163130/pexels-photo-8163130.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          href: "guide-sintra",
          badge: {
            text: "Blog article",
            icon: require("../assets/newspaper-solid.svg"),
          },
          date: "March 10, 2024",
        },
      ],
      Attractions: [
        {
          name: "Jerónimos Monastery",
          experiences: ["tILBrMVXOzLveIM403Je", "FyBrCr0owDZFSWu7mag7"],
          tickets: ["tILBrMVXOzLveIM403Je"],
          description:
            "Jerónimos Monastery is an iconic monastery located in Lisbon, Portugal, known for its stunning Manueline architecture and historical significance.",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20199.png?alt=media&token=fc8c21a6-a875-426c-865b-c6ac6ffba3dd",
        },
        {
          name: "Belém Tower",
          experiences: ["FyBrCr0owDZFSWu7mag7"],
          tickets: ["tILBrMVXOzLveIM403Je"],
          description:
            "Belém Tower is a historic fortress located in Lisbon, Portugal, celebrated for its unique Manueline architecture and picturesque waterfront setting.",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20200.png?alt=media&token=0a18ee2d-3bc3-427e-8449-97d1b73d28c6",
        },
        {
          name: "São Jorge Castle",
          experiences: ["63mFURw6KKq6ZFTmGtMQ"],
          tickets: [],
          description:
            "São Jorge Castle, a historic fortress on Lisbon's highest hill, offers panoramic views of the city and the Tagus River while serving as a symbol of Portugal's rich cultural heritage.",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsjorge.png?alt=media&token=ea8763a8-77fc-4439-aba5-4a682a69ec92",
        },
        {
          name: "Lisbon Cathedral",
          experiences: [
            "63mFURw6KKq6ZFTmGtMQ",
            "FyBrCr0owDZFSWu7mag7",
            "t78nJPOAbsW4DuwDnZa8",
          ],
          tickets: [],
          description:
            "Lisbon Cathedral, also known as the Sé de Lisboa, stands as a majestic example of Romanesque architecture in Portugal, blending centuries of history and religious significance within its ornate interior and commanding facade.",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fse.png?alt=media&token=d02a8b2b-e03a-4143-a0ea-23c199262ec2",
        },
      ],
    };
  },
  methods: {
    onCategoryClick(index) {
      this.activeTab = index;
      this.$refs.activities.reset();
    },
    prepareTickets(ticketIds) {
      if (!this.tickets) return;
      return this.tickets.filter((ticket) => ticketIds.includes(ticket.id));
    },
    getExperienceById(experiences, type = "tours") {
      return this[type].filter((element) => experiences.includes(element.id));
    },
    getExperiences(activitiesIds, ticketsIds) {
      const activities = this.getExperienceById(activitiesIds);
      console.log({activities});
      const tickets = this.getExperienceById(ticketsIds, "tickets");
      return [...activities, ...tickets];
    },
    setOrderedTickets(items) {
      this.orderedTickets = items;
    },
    getAllExperiences() {
      const experiences = [];
    //  const passes= []
      /* const showAllActivities = window.location.origin !== 'https://guiders.pt'
            this.tours.map((tour) =>{ 
                if (showAllActivities) experiences.push(tour)
                if (!showAllActivities && tour.isVisible) experiences.push(tour)
            }) */
      this.tours.map((tour) => {
        /*  if (tour.isPass) {
        //  passes.push(tour)
          return
        }  */
        experiences.push(tour)
      });
      this.tickets.map((tour) => experiences.push(tour));

      return experiences;
    },
  },
  computed: {
    ...mapState({
      tours: (state) => state.activities[ActivitiesStates.ACTIVITIES],
    }),

    activities() {
      /*  let filteredObj = {};
             Object.keys(this.orderedActivities).forEach(key => {
                 filteredObj[key] = this.orderedActivities[key].filter(item => item.cityId == 0);
             });
  
            return filteredObj*/
      return this.orderedActivities;
    },
    ticketsCategories() {
      let ticketsCategories = {};
      this.tickets.map((tour) => {
        if (!tour?.categories) return;
        Categories.forEach((category) => {
          if (category.index === 0)
            return (ticketsCategories["All"] = this.getAllExperiences());
          if (tour.categories.includes(category.index))
            ticketsCategories[category.name] = !ticketsCategories[category.name]
              ?.length
              ? [tour]
              : [...ticketsCategories[category.name], tour];
        });
      });
      this.setOrderedTickets(ticketsCategories);
      return ticketsCategories;
    },
    categoriesKeys() {
      const ticketsCategories = Object.keys(this.ticketsCategories);
      const activityCategories = Object.keys(this.activities);
      /*  console.log({categories});
             const ticketsCategories = this.ticketsCategories
             const allCategories = categories.push(ticketsCategories)
             for (let key in allCategories) {
                 // Check if the value associated with the key is an empty array
                 if (Array.isArray(allCategories[key]) && allCategories[key].length === 0) {
                     // Remove the key from the object
                     delete allCategories[key];
                 }
             } 
 
             let Categories = Object.keys(allCategories)*/
      const categories = [...activityCategories, ...ticketsCategories];
      const uniqueCategories = [...new Set(categories)];
      return uniqueCategories.sort((a, b) => {
        // Custom ordering for "Tuk Tuk" and "Day Trip"
        const customOrder = {
          "all": 1,
          "tuk tuk": 2,
          "day trip": 3,
        };

        // Convert names to lowercase for case-insensitive sorting
        let nameA = a.toLowerCase();
        let nameB = b.toLowerCase();

        // Check if either name is in the custom order
        const orderA = customOrder[nameA] || 999; // If not "Tuk Tuk" or "Day Trip", set a large value (999) to sort later
        const orderB = customOrder[nameB] || 999;

        // Sort by custom order first
        if (orderA !== orderB) {
          return orderA - orderB;
        }

        // Fallback to regular alphabetical sorting if both have the same custom order (or neither are custom)
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // Names are equals
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

::v-deep {
  .button .icon:last-child:not(:first-child) {
    margin-left: 0.45em;
    margin-right: calc(-0.5em - 1px);
  }
}

.bg-light-blue {
  background-color: #dcedff7a;
  border-radius: 20px;
  margin: -20px;
  span {
    transform: rotate(-45deg) !important;
  }
  .svg-inline--fa.fa-w-14 {
    width: 0.9em !important;
  }
}

.blog-articles {
  margin: -20px;
  padding: 20px;
  margin-bottom: 2.25rem;
}

.categories-container {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-right: -20px;
  margin-left: -20px;
  padding-left: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  .button:not(.is-primary) {
    border: 1px solid #0e3d4d;
  }

  .category--tab {
    height: 2.5rem;
  }
}

.attraction {
  position: relative;
  height: 264px;

  .attraction--tile {
    color: #0e3d4d;
    position: absolute;
    left: 12px;
    bottom: 12px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .highlight {
    background-color: #73fbd3;
    padding: 2px 11px;
    border-radius: 4px;
    -webkit-box-decoration-break: clone;
  }

  .attraction--img {
    border-radius: 12px 12px 0 0;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
}

.attraction-details--container {
  border-radius: 0 0 8px 8px;
  margin-bottom: 1rem;

  .attraction--details {
    background-color: #f9fffe;
    padding: 12px;
    font-weight: 500;
  }
}

.collapse--btn {
  background-color: rgb(236 255 251);
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.divider {
  position: absolute;
}

.category--tab {
  height: 3rem;
  font-weight: 700;
  font-size: 0.9rem;

  &:not(:first-of-type) {
    margin-left: 0.5rem;
  }
}

.title {
  font-size: 1.35rem;
  font-weight: 500;
}

.subtitle {
  // font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}

.home .select select,
.taginput .taginput-container.is-focusable,
.input {
  margin-bottom: 0px !important;
}

@media (min-width: 1024px) {
  .columns {
    justify-content: flex-start;
  }
}

.cards-container {
  align-items: flex-end;
  margin: 2rem 0;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, minmax(min-content, 7fr));
}

.cards-container--mobile {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
